import React from "react"
import css from "@styled-system/css"
import { Flex, H6, Text, Box } from "components"

const Finish = ({ image, title, subtitle, url, comingSoon }) => (
  <Flex
    width={["100%", 1 / 2, 1 / 2, 1 / 4]}
    p={3}
    as={url ? "a" : "div"}
    target="_blank"
    rel="noopener noreferrer"
    href={url}
    css={css({ textDecoration: "none" })}
  >
    <Flex width="100%" flexDirection="column">
      <Box
        css={css({
          position: "relative",
          backgroundImage:
            image &&
            "url(" +
              image +
              "?fit=crop&crop=center&max-w=600&max-h=600&auto=compress)",
          backgroundSize: "cover",
          backgroundPosition: "center",
        })}
        pb="100%"
        bg="muted"
      >
        {comingSoon && (
          <Box
            css={css({
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              bg: "rgba(0, 0, 0, 0.33)",
            })}
          >
            <H6
              children={`Coming soon`}
              color="textReverse"
              textAlign="center"
              css={css({
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              })}
            />
          </Box>
        )}
        {!image && (
          <H6
            children={`Coming soon`}
            color="textReverse"
            textAlign="center"
            css={css({
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            })}
          />
        )}
      </Box>
      <H6 children={title} mt={[3, 4]} mb={2} />
      {subtitle && <Text children={subtitle} />}
    </Flex>
  </Flex>
)

export default Finish
