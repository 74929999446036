import React from "react"
import css from "@styled-system/css"

import { Box, Column, Container, Gallery, H2 } from "components"

function FocusSection({ title, openImage, images }) {
  const setImage = function(image) {
    openImage(images, image)
  }

  return (
    <Box
      as="section"
      bg="background"
      css={css({
        pt: [5, 6],
        "&:last-of-type": {
          pb: [5, 6],
        },
      })}
    >
      <Container>
        <Column left>
          <H2 children={title} />
        </Column>
        <Box mt={[4, 5]}>
          <Gallery itemsPerRow={[2, 2, 3]} images={images} onClick={setImage} />
        </Box>
      </Container>
    </Box>
  )
}

export default FocusSection
