import React from "react"

import { Box, Container } from "components"

const Video = ({ content }) => (
  <Box key={content.id} my={[5, 6]}>
    <Container maxWidth={800}>
      <iframe
        width="100%"
        height="450px"
        src={content.youtubeLink}
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Container>
  </Box>
)

export default Video
