import React from "react"
import Img from "gatsby-image"
import { motion } from "framer-motion"
import css from "@styled-system/css"
import ReactMarkdown from "react-markdown"

import { Button, Box, Dialog, Flex, H2, H3, Text, FadeText } from "components"

import rightCursor from "../../../../static/images/rightCursor.png"
import leftCursor from "../../../../static/images/leftCursor.png"

const ImageDetails = ({
  setShowDialog,
  showDialog,
  activeGallery,
  activeImage,
  next,
  nextPossible,
  previous,
  previousPossible,
}) => (
  <>
    <Dialog setShowDialog={setShowDialog} showDialog={showDialog}>
      <>
        {activeGallery.map((image, index) => {
          if (image.id === activeImage.id) {
            return (
              <Box
                css={css({
                  display: "grid",
                  gridTemplateColumns: ["1fr", null, "3fr 64px 2fr"],
                  msGridTemplateColumns: ["1fr", null, "3fr 64px 2fr"],
                  gridTemplateRows: ["auto 32px auto", null, "auto"],
                  msGridTemplateRows: ["auto 32px auto", null, "auto"],
                })}
                key={`gallery-${index}`}
              >
                <Box
                  css={css({
                    gridColumn: 1,
                    msGridColumn: 1,
                    gridRow: 1,
                    msGridRow: 1,
                  })}
                  position="relative"
                >
                  <motion.div
                    css={css({
                      transformOrigin: "center left",
                      position: "absolute",
                      bottom: 0,
                      top: 0,
                      left: 0,
                      right: 0,
                      zIndex: 20,
                      pointerEvents: "none",
                    })}
                    variants={{
                      initial: {
                        scaleX: 1,
                      },
                      enter: {
                        scaleX: 0,
                        transition: {
                          duration: 0.75,
                          when: "beforeChildren",
                        },
                      },
                      exit: {
                        scaleX: 0,
                        transition: { duration: 0.5 },
                      },
                    }}
                    initial="initial"
                    animate="enter"
                    exit="exit"
                  >
                    <Box height="100%" width="100%" bg="#fff" zIndex={100} />
                  </motion.div>
                  <Img
                    fluid={image.fluid && image.fluid}
                    css={css({
                      width: "auto",
                      maxWidth: "100%",
                      height: ["33vh", "75vh"],
                      verticalAlign: "top",
                      objectFit: "contain",
                    })}
                    imgStyle={{ objectFit: "contain" }}
                    objectFit="contain"
                  />
                  {previousPossible && (
                    <Box
                      position="absolute"
                      left={0}
                      width="50%"
                      right={"50%"}
                      top={0}
                      bottom={48}
                      css={css({ cursor: `url(${leftCursor}), auto` })}
                      onClick={previous}
                    />
                  )}
                  {nextPossible && (
                    <Box
                      position="absolute"
                      left="50%"
                      width="50%"
                      right={0}
                      top={0}
                      bottom={48}
                      css={css({ cursor: `url(${rightCursor}), auto` })}
                      onClick={next}
                    />
                  )}
                  <Flex justifyContent="space-between" pt={3}>
                    <svg
                      width="25"
                      height="14"
                      viewBox="0 0 25 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      style={{
                        cursor: "pointer",
                        opacity: previousPossible ? 1 : 0.4,
                      }}
                      onClick={previousPossible && previous}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M0.171386 6.99998L-0.000151278 7.16578L7.07092 14.0001L8.48513 12.6332L3.69153 8.00012L24.4854 8.00012L24.4854 6.00012L3.69154 6.00012L8.48528 1.36691L7.07107 5.58818e-05L-6.26442e-07 6.83434L0.171386 6.99998Z"
                        fill="#595C5B"
                      />
                    </svg>

                    <svg
                      width="25"
                      height="14"
                      viewBox="0 0 25 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      onClick={nextPossible && next}
                      style={{
                        cursor: "pointer",
                        opacity: nextPossible ? 1 : 0.4,
                      }}
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.314 7L24.4855 6.83423L17.4144 0L16.0002 1.36682L20.7938 5.99988H0V7.99988H20.7938L16.0001 12.6331L17.4143 14L24.4854 7.16565L24.314 7Z"
                        fill="#595C5B"
                      />
                    </svg>
                  </Flex>
                </Box>
                <Flex
                  flexDirection="column"
                  css={css({
                    gridColumn: [1, null, 3],
                    msGridColumn: [1, null, 3],
                    gridRow: [3, null, 1],
                    msGridRow: [3, null, 1],
                  })}
                >
                  <FadeText delay={0.1}>
                    <H2 children={image.title} />
                    <H3 children={image.subtitle} />
                    <Text
                      children={image.dimensions}
                      mt={[3, 4]}
                      fontSize={[3, 4]}
                      css={css({ whiteSpace: "pre" })}
                    />
                    {image.details && (
                      <Box mt={[4, 5]}>
                        <ReactMarkdown
                          source={image.details}
                          css={css({
                            whiteSpace: "pre",
                            "p, a": {
                              fontSize: [3, 4],
                              // fontWeight: "body",
                              // lineHeight: "body",
                            },
                            "em ": {
                              fontSize: [2, 3],
                              color: "text",
                              opacity: 0.7,
                              // fontWeight: "body",
                              // lineHeight: "body",
                            },
                            "h1, h2, h3, h4, h5, h6": {
                              fontSize: [2, 3],
                              fontWeight: "700",
                              lineHeight: "body",
                              letterSpacing: "0.035em",
                              textTransform: "uppercase",
                            },
                          })}
                        />
                      </Box>
                    )}
                    {image.panoramaLink && image.panoramaLink.link && (
                      <Button
                        children={"View panorama"}
                        as={"a"}
                        href={image.panoramaLink.link}
                        rel="noopener noreferrer"
                        target="_blank"
                        mt={4}
                      />
                    )}

                    {image.callToActionTitle && image.callToActionUrl && (
                      <Button
                        children={image.callToActionTitle}
                        as={"a"}
                        href={image.callToActionUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        mt={4}
                      />
                    )}
                  </FadeText>
                </Flex>
              </Box>
            )
          } else {
            return null
          }
        })}
      </>
    </Dialog>
  </>
)

export default ImageDetails
