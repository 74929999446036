import React, { useState, useEffect } from "react"
import css from "@styled-system/css"
import { graphql, Link } from "gatsby"

import {
  Box,
  Flex,
  H2,
  H6,
  Container,
  SEO,
  Text,
  QuoteSection,
  Button,
} from "components"

import TextBlock from "../post/components/textBlock"
import FocusSection from "./_sections/_focus"
import ImageDetails from "./_components/_imageDetails"
import Image from "../post/components/image"
import CallToAction from "../post/components/callToAction"
import Video from "../post/components/video"
import Finish from "../../pages/components/finishes-materials/_sections/_finish"

const ProjectPage = ({ data }) => {
  const [previousProject, setPreviousProject] = useState(undefined)
  const [nextProject, setNextProject] = useState(undefined)

  const [nextPossible, setNextPossible] = useState(false)
  const [previousPossible, setPreviousPossible] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const [activeGallery, setActiveGallery] = useState([])
  const [activeImage, setActiveImage] = useState(undefined)

  useEffect(() => {
    if (data.projects && data.projects.nodes) {
      data.projects.nodes.forEach((project, index) => {
        if (project.url === data.project.url) {
          if (data.projects.nodes[index - 1]) {
            setNextProject(data.projects.nodes[index - 1].url)
          }
          if (data.projects.nodes[index + 1]) {
            setPreviousProject(data.projects.nodes[index + 1].url)
          }
        }
      })
    }
  }, [data.projects, data.project.url])

  useEffect(() => {
    setPreviousPossible(false)
    setNextPossible(false)
    activeGallery.forEach((image, index) => {
      if (image.id === activeImage.id) {
        if (index > 0) {
          setPreviousPossible(true)
        }
        if (index < activeGallery.length - 1) {
          setNextPossible(true)
        }
      }
    })
  }, [activeGallery, activeImage])

  const next = function() {
    activeGallery.forEach((image, index) => {
      if (image.id === activeImage.id) {
        setActiveImage(activeGallery[index + 1])
      }
    })
  }

  const previous = function() {
    activeGallery.forEach((image, index) => {
      if (image.id === activeImage.id) {
        setActiveImage(activeGallery[index - 1])
      }
    })
  }

  const openImage = function(images, image) {
    setActiveGallery(images)
    setActiveImage(image)
    setModalOpen(true)
  }

  return (
    <>
      <SEO
        title={data.project.title + " - Project - Falk"}
        description={
          "Read about our project entitled " +
          data.project.title +
          " on the Falkbuilt Technologies site."
        }
        image={data.project.image && data.project.image.url}
      />
      <Box pt={[150, 170]} pb={[5, 6]} px={[0, null, 4, 6]} bg="muted">
        <Box mx="auto" maxWidth={1600}>
          <Box mt={[0, 5]} bg="background">
            <Container py={[5, null, null, 6]}>
              <div
                css={css({
                  display: ["block", "flex"],
                  justifyContent: "space-between",
                })}
              >
                <div css={css({ flex: "0 1 60%" })}>
                  <H2 as="h1" children={data.project.title} mb={3} />
                  {data.project.subtitle && (
                    <Text
                      children={data.project.subtitle}
                      mt={[3, 4]}
                      fontFamily="body"
                      fontSize={[4, 5]}
                    />
                  )}
                </div>
                <div css={css({ flex: "0 1 35%", fontSize: [3, 4] })}>
                  <ul css={css({ listStyle: "none", mt: [2, 0], pl: [0, 4] })}>
                    <li>
                      <strong>Client:</strong> {data.project.clientName}
                    </li>
                    <li>
                      <strong>Year:</strong> {data.project.year}
                    </li>
                    <li>
                      <strong>Branch:</strong> {data.project.branch.name}
                    </li>
                  </ul>
                </div>
              </div>
            </Container>

            {data.project.contentBlocks.map((content, index) => {
              if (content.__typename === "DatoCmsTextBlock") {
                return <TextBlock content={content} maxWidth={"100%"} />
              }
              if (content.__typename === "DatoCmsImage") {
                return <Image content={content} />
              }
              if (content.__typename === "DatoCmsProjectGallery") {
                const images = content.galleryItems.map((imageItem, index) => {
                  return {
                    ...imageItem,
                    fluid: imageItem.image.fluid,
                    alt: imageItem.image.alt,
                    aspectRatio:
                      imageItem.image.fluid &&
                      imageItem.image.fluid.aspectRatio,
                    id: index,
                  }
                })

                return (
                  <FocusSection
                    title={content.title}
                    images={images}
                    openImage={openImage}
                  />
                )
              }
              if (content.__typename === "DatoCmsCallToAction") {
                return <CallToAction content={content} />
              }
              if (content.__typename === "DatoCmsVideo") {
                return <Video content={content} />
              }
              if (content.__typename === "DatoCmsFinish") {
                return (
                  <Finish
                    image={
                      content.image && content.image.url && content.image.url
                    }
                    title={content.title}
                    subtitle={content.subtitle}
                    url={content.url}
                    comingSoon={content.comingSoon}
                  />
                )
              }
              if (content.__typename === "DatoCmsQuote") {
                return (
                  <QuoteSection quote={content.quote} name={content.name} />
                )
              }

              return undefined
            })}
            <Box height={1} />
            {data.project.finishes.length > 0 && (
              <Container>
                <Box width="100%">
                  <H2 children={"Finishes"} textAlign="left" />
                </Box>

                <Flex alignItems="center" flexWrap={["wrap", "nowrap"]} pb={5}>
                  <Flex pt={5} flexWrap="wrap" justifyContent="flex-start">
                    {data.project.finishes.map((finish, index) => (
                      <div key={`finish-${index}`} css={css({ mr: 4 })}>
                        <Box
                          css={css({
                            position: "relative",
                            backgroundImage:
                              finish.image.url &&
                              "url(" +
                                finish.image.url +
                                "?fit=crop&crop=center&max-w=600&max-h=600&auto=compress)",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            width: "150px",
                            height: "150px",
                          })}
                          bg="muted"
                        ></Box>
                        <H6 children={finish.title} mt={3} mb={2} />
                      </div>
                    ))}
                  </Flex>
                  <Button
                    children={"See on Design Mix"}
                    as="a"
                    href={data.project.finishesUrl}
                    css={css({ mt: [4, 0], ml: [0, "auto"] })}
                  />
                </Flex>
              </Container>
            )}
            <Container>
              <Box width="100%" pb={4}>
                <H2>Filters</H2>
                <Text
                  children={
                    <ul
                      css={css({
                        display: "inline-block",
                        pl: 1,
                      })}
                    >
                      {data.project.projectCategories.map(category => {
                        return (
                          <li
                            css={css({
                              display: "inline-block",
                              mr: 1,
                              "&:after": {
                                content: '", "',
                              },
                              "&:last-child:after": {
                                content: '""',
                              },
                            })}
                          >
                            {category.name}
                          </li>
                        )
                      })}
                    </ul>
                  }
                  fontFamily="body"
                  fontSize={[4, 5]}
                />
              </Box>
            </Container>
          </Box>
        </Box>
        <Box mt={[5, 6]}>
          <Flex justifyContent="center" alignItems="center">
            {previousProject && (
              <H6>
                <Link
                  children={`Previous project`}
                  to={"/vertical-markets/" + previousProject + "/"}
                  css={css({ textDecoration: "none" })}
                />
              </H6>
            )}
            {previousProject && nextProject && (
              <Box
                width="1px"
                height={48}
                bg="primary"
                mx={4}
                css={css({ transform: "translateY(-2px) rotate(45deg)" })}
              />
            )}
            {nextProject && (
              <H6>
                <Link
                  children={`Next project`}
                  to={"/vertical-markets/" + nextProject + "/"}
                  css={css({ textDecoration: "none" })}
                />
              </H6>
            )}
          </Flex>
        </Box>
      </Box>
      <ImageDetails
        showDialog={modalOpen}
        setShowDialog={setModalOpen}
        activeGallery={activeGallery}
        activeImage={activeImage}
        data={data}
        next={next}
        nextPossible={nextPossible}
        previous={previous}
        previousPossible={previousPossible}
      />
    </>
  )
}

export const query = graphql`
  query ProjectQuery($handle: String!) {
    projects: allDatoCmsProject(sort: { order: DESC, fields: year }) {
      nodes {
        url
      }
    }
    project: datoCmsProject(url: { eq: $handle }) {
      url
      title
      subtitle
      clientName
      projectCategories {
        name
      }
      year
      branch {
        name
      }
      finishes {
        title
        image {
          url
          title
        }
      }
      finishesUrl
      contentBlocks {
        __typename
        ... on DatoCmsTextBlock {
          id
          text
        }
        ... on DatoCmsProjectGallery {
          id
          title
          galleryItems {
            image {
              alt
              fluid(maxWidth: 1200) {
                ...GatsbyDatoCmsFluid
              }
            }
            category
            title
            subtitle
            details
            dimensions
            panoramaLink {
              link
            }
            callToActionTitle
            callToActionUrl
          }
        }
        __typename
        ... on DatoCmsImage {
          image {
            url
            alt
          }
        }
        __typename
        ... on DatoCmsCallToAction {
          id
          title
          buttonText
          buttonLink
        }
        ... on DatoCmsVideo {
          id
          youtubeLink
        }
        ... on DatoCmsQuote {
          name
          quote
        }
      }
    }
  }
`

export default ProjectPage
